export default [
    
    
    {
      path: "/politica-de-privacidad",
      text: "Politica de privacidad",
    },
    {
        path: "/terminos-de-uso",
        text: "Terminos de uso",
      },
    
   
  ]